import React from "react"
import { graphql } from "gatsby"
import '../styles/page.css'
import '../styles/case.css'
import { css } from '@emotion/react'
import { GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import useSiteMetadata from '../hooks/use-site-metadata';
import Navbar from "../components/navbar-nonhome"
import ReactHtmlParser from 'react-html-parser'
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
  } from "react-share";

import {
FacebookIcon,
LinkedinIcon,
TwitterIcon,
} from "react-share";
import {Trans, Link, I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next';


export default function BlogPost({data, location, pageContext}) {
  const context = React.useContext(I18nextContext);
  const taal = context.language
    const post = data.wpPost
    const { siteUrl } = useSiteMetadata()
        if (post.nieuws.categorie === "nieuws") {
            var categoriestyle = "nieuws"
            var backtoliststyle = "backtolistnieuws"
            var bloggallerystyle = "bloggallerynieuws"
            var nextpoststyle = "nextpostnieuws"
            var previouspoststyle = "previouspostnieuws"
            var shareiconstyle = "shareiconnieuws"
        } else {
            categoriestyle = "project"
            backtoliststyle = "backtolistproject"
            bloggallerystyle = "bloggalleryproject"
            nextpoststyle = "nextpostproject"
            previouspoststyle = "previouspostproject"
            shareiconstyle = "shareiconproject"
        }
        if (post.nieuws.categorie === "nieuws" && taal === "nl") {var content = "nieuws"}
        if (post.nieuws.categorie === "nieuws" && taal === "fr") {var content = "actualités"}
        if (post.nieuws.categorie === "project" && taal === "nl") {var content = "project"}
        if (post.nieuws.categorie === "project" && taal === "fr") {var content = "projet"}

    return (<Layout>
      <Helmet titleTemplate="%s | Asbitech"
            htmlAttributes={{
              lang: `${taal}`,
            }}
            >
            <title>{post.title}</title>
            <meta name="description" content={post.nieuws.korteInleiding.replace(/(<([^>]+)>)/gi, '')} />
            <meta property="og:image" content={`${siteUrl}${post.nieuws.afbeeldingsgallerij[0].localFile.publicURL}`}/>
            <meta name="twitter:image" content={`${siteUrl}${post.nieuws.afbeeldingsgallerij[0].localFile.publicURL}`} />
            <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
        <Navbar></Navbar>

        <BackgroundImage className="blogheader" altText={post.nieuws.afbeeldingsgallerij[0].altText} fluid={post.nieuws.afbeeldingsgallerij[0].localFile.childImageSharp.fluid} fadeIn="false">
      </BackgroundImage>

      <div className="postcontainer">
        <div className="blogcontainer">
          <div className={backtoliststyle}><Link to="/nieuws"><Trans>Alle artikels</Trans></Link></div>
          <div css={css`margin-bottom: 1rem;`}><span className={categoriestyle}>{content}</span></div>
          <h1 css={css`margin-bottom: 1rem;`}>{post.title}</h1>
              <article>{ReactHtmlParser(post.content)}</article>
        </div>


       <div className="blogsidebar" css={css``}>

              {post.nieuws.afbeeldingsgallerij.map(({ index, localFile, altText }) =>
                (
                  <div  key={index} css={css`text-align: center;
                  margin: auto auto;`}>
                      <GatsbyImage className={bloggallerystyle}
            image={localFile.childImageSharp.gatsbyImageData}
            alt={altText}
            layout="fixed"
          />
                  </div>


              ))}
              <div css={css`display: flex; font-size: 0.8rem;`}><div className={shareiconstyle}/><span>
              <FacebookShareButton className="sharebutton" css={css`margin-left: 5px;`} url={`${siteUrl}${location.pathname}`}>
                  <FacebookIcon className="sharebutton" css={css`margin-right: 0rem; margin-left: 0px;`} size={24} round={false}></FacebookIcon>
        </FacebookShareButton>
        <LinkedinShareButton className="sharebutton" url={`${siteUrl}${location.pathname}`}><LinkedinIcon className="sharebutton" size={24} round={false}></LinkedinIcon></LinkedinShareButton>
        <TwitterShareButton className="sharebutton" url={`${siteUrl}${location.pathname}`}><TwitterIcon className="sharebutton" size={24} round={false}></TwitterIcon></TwitterShareButton>

        </span> </div>
              </div>


          </div>

    </Layout>



    )}

export const query = graphql`
query($slug: String!, $language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
    wpPost (slug: {eq: $slug}, language: {locale: {eq: $language}}) {
        title
        uri
        slug
        content
        nieuws {
          korteInleiding
          categorie
          afbeeldingsgallerij {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                gatsbyImageData
              }
            }
          }
        }
      }
    }
     `
